<!-- @format -->

<template>
	<div>
		<div class="text-bolder table-color">
			Select customer from bellow to show customer's history
		</div>
		<div>
			<ve-table
				:rows="rowsList"
				:columns="columnsList"
				:onCellClick="onCellClick"
				:onRowDoubleClick="onRowDoubleClick"
				:sort-options="sortOptions"
			>
			</ve-table>
		</div>
	</div>
</template>

<script>
import { ref, reactive, onMounted } from '@vue/composition-api'
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'
import GetCustomerListQuery from '@/_srcv2/pages/admin/customer-history/graphql/GetCustomerListQuery.graphql'
import useGetCustomerEconomicInfo from '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/scripts/useGetCustomerEconomicInfo.js'

export default {
	name: 'GetSelectedCustomer',
	setup() {
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchCustomers } = useQuery(
			GetCustomerListQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const refetchData = () => {
			refetchCustomers().then((result) => {
				rowsList.value = result.data.customers
				console.log('rowsList.value', rowsList.value)
			})
		}
		const { showSearch, showComponents } = useGetCustomerEconomicInfo()
		onMounted(() => {
			// showSearch.value = true
			refetchData()
		})
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'customer_title', type: 'asc' },
		}
		// get columns
		const { columnsList } = useGetCustomerEconomicInfo()
		// get rows
		const rowsList = ref([])
		// *-----------------------------------------------------------------------------
		const { customer } = useGetCustomerEconomicInfo()
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log(params.row)
				customer.value = params.row
				showSearch.value = false
				showComponents.value = true
			}
		}
		const onRowDoubleClick = (params) => {
			console.log(params.row)
			customer.value = params.row
			// showSearch.value = false
		}
		return {
			showSearch,
			sortOptions,
			columnsList,
			rowsList,
			onCellClick,
			onRowDoubleClick,
		}
	},
}
</script>

<style scoped></style>
