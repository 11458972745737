<template>
	<div>
		<section>
			<div
				style="
					margin-bottom: -20px;
					background-color: rgb(219, 225, 235);
					padding-left: 15px;
					padding-top: 30px;
					padding-bottom: 20px;
					padding-right: 15px;
				"
			>
				<b-field label="Select beginning date">
					<b-datepicker
						v-model="selectedBeginningDate"
						:first-day-of-week="firstDayOfWeek"
						:show-week-number="showWeekNumber"
						:locale="locale"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
				<b-field label="Select ending date">
					<b-datepicker
						v-model="selectedEndingDate"
						:show-week-number="showWeekNumber"
						:first-day-of-week="firstDayOfWeek"
						:locale="locale"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
				<div style="margin-top: 10px; padding: 15px">
					<b-button
						class="width-250"
						style="background-color: rgb(52, 136, 206); color: white"
						@click="getData"
					>
						Get Results
					</b-button>
					<b-button
						class="margin-left-30 width-250"
						style="background-color: rgb(109, 65, 161); color: white"
						@click="resetForm"
					>
						Clean the Form
					</b-button>
				</div>
			</div>
			<div>
				<b-table
					class="margin-top-20"
					:data="listArr"
					:columns="columns"
					:bordered="true"
					:striped="true"
					:narrowed="true"
					:hoverable="false"
					:loading="false"
					:focusable="false"
					:mobile-cards="true"
				>
					<template #footer v-if="true">
						<div class="has-text-right">
							{{ totalConsumption }}
							<br />
							{{ numberOfStockItemTypes }}
						</div>
					</template>
				</b-table>
			</div>
		</section>
	</div>
</template>

<script>
import {
	computed,
	onMounted,
	reactive,
	ref,
	watchEffect,
} from '@vue/composition-api'
import dateformat from 'dateformat'
import addDays from 'add-days'
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'
import GetCustomerConsumptionItems from '@/_srcv2/graphql/admin-reports/queries/getCustomerConsuptionItems.query.gql'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber'
import useGetCustomerEconomicInfo from '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/scripts/useGetCustomerEconomicInfo.js'

export default {
	name: 'GetCustomerConsumptionsSubComponent',
	setup() {
		const labelPosition = ref('on-border')
		const { customer } = useGetCustomerEconomicInfo()
		const selectedCustomer = computed(() => customer.value.customer_id)
		const parameters = reactive({
			beginningDate: '',
			endingDate: '',
		})
		const selectedBeginningDate = ref(new Date())
		watchEffect(() => {
			parameters.beginningDate = dateformat(
				selectedBeginningDate.value,
				'yyyy-mm-dd',
			)
		})
		const selectedEndingDate = ref(new Date())
		watchEffect(() => {
			parameters.endingDate = dateformat(selectedEndingDate.value, 'yyyy-mm-dd')
		})
		const showWeekNumber = false
		const locale = 'sv-SE'
		const firstDayOfWeek = ref(1)
		// Time Interval Dispatches
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchDispatch, onResult } = useQuery(
			GetCustomerConsumptionItems,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				customer_id: selectedCustomer.value,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		onResult((result) => console.log('onResult is fired', result))
		const { floatedCurrencyAddedNumber } = useReportNumber()
		// Create Table
		const tableValues = ref([])
		const tableTotals = reactive({
			kindsOfItems: 0,
			totalConsumption: 0,
		})
		const getData = () => {
			tableValues.value = []
			tableTotals.kindsOfItems = 0
			tableTotals.totalConsumption = 0
			refetchDispatch().then((result) => {
				console.log('result from promise', result)
				tableValues.value = result.data.goods_transactions
					.map((item) => {
						return {
							stockId: item.goods_transactions_stock_rel.stock_id,
							itemName: item.goods_transactions_stock_rel.stock_name,
							amount:
								item.goods_transaction_goods_transaction_rel_aggregate.aggregate
									.sum.amount_credit,
							consumptionWorth: floatedCurrencyAddedNumber(
								item.goods_transaction_goods_transaction_rel_aggregate.aggregate
									.sum.line_price_total_credit,
							),
						}
					})
					.filter((item) => item.amount !== 0)
				// tableTotals.kindsOfItems =
				//   result.data.goods_transactions_aggregate.aggregate.count
				tableTotals.totalConsumption = floatedCurrencyAddedNumber(
					result.data.goods_transactions_aggregate.aggregate.sum
						.line_price_total_credit,
				)
				tableTotals.kindsOfItems = tableValues.value.length
			})
		}
		const listArr = computed(() => tableValues.value)
		const columns = [
			{
				field: 'stockId',
				label: 'Stock Id',
				width: '110',
				centered: true,
			},
			{
				field: 'itemName',
				label: 'Item Name',
				width: '350',
			},
			{
				field: 'amount',
				label: 'Amount',
				width: '40',
				centered: true,
			},
			{
				field: 'consumptionWorth',
				label: 'Consumption',
				width: '60',
				numeric: true,
			},
		]
		const resetForm = () => {
			tableValues.value = []
			tableTotals.kindsOfItems = 0
			tableTotals.totalConsumption = 0
			const today = new Date()
			selectedEndingDate.value = today
			selectedBeginningDate.value = addDays(today, -90)
		}
		onMounted(() => {
			resetForm()
		})
		const numberOfStockItemTypes = computed(
			() => `Number of stock item types: ${tableTotals.kindsOfItems}`,
		)
		const totalConsumption = computed(
			() => `Total Consumption: ${tableTotals.totalConsumption}`,
		)
		// ? ----------------------------------------------------------------------------
		return {
			resetForm,
			labelPosition,
			selectedCustomer,
			selectedBeginningDate,
			selectedEndingDate,
			showWeekNumber,
			locale,
			firstDayOfWeek,
			getData,
			listArr,
			columns,
			numberOfStockItemTypes,
			totalConsumption,
		}
	},
}
</script>

<style lang="scss" scoped></style>
