<template>
	<div>
		{{ customerInfo }}
	</div>
</template>

<script>
import useGetCustomerEconomicInfo from '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/scripts/useGetCustomerEconomicInfo.js'
import { computed } from '@vue/composition-api'
export default {
	name: 'CustomerHeaderInfo',
	setup() {
		const { customer } = useGetCustomerEconomicInfo()

		const customerInfo = computed(
			() =>
				`${customer.value.customer_id} - ${customer.value.customer_title} - ${customer.value.customer_nickname}`,
		)
		return {
			customerInfo,
		}
	},
}
</script>

<style lang="scss" scoped></style>
