<template>
	<div>
		<div v-if="showComponents"><GetCustomerInvoices /></div>
	</div>
</template>

<script>
import GetCustomerInvoices from '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/components/GetCustomerInvoices.vue'
import useGetCustomerEconomicInfo from '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/scripts/useGetCustomerEconomicInfo.js'
import { onMounted } from '@vue/composition-api'

export default {
	name: 'GetCustomerPaymentsHistorySubComponent',
	components: {
		GetCustomerInvoices,
	},
	setup() {
		const { showComponents } = useGetCustomerEconomicInfo()
		onMounted(() => {
			// showComponents.value = false
		})
		return {
			showComponents,
		}
	},
}
</script>

<style lang="scss" scoped></style>
