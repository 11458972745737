<template>
	<LayoutWide>
		<sub-nav-bar page="Marketing" />
		<div>
			<HeroBox :title="title" :subtitle="subtitle" :color="color" />
		</div>
		<div v-if="showSearch"><GetSelectedCustomer /></div>
		<div v-if="showComponents">
			<div class="table-color">
				<CustomerHeaderInfo v-if="showComponents" />
			</div>
			<!-- <section>
			<div>
				<GetCustomersLastFourWeeksDeliveriesSubComponent />
			</div>
		</section> -->
			<section>
				<div>
					<GetCustomerPaymentsHistorySubComponent v-if="showComponents" />
				</div>
			</section>
			<section>
				<div>
					<GetCustomerConsumptionsSubComponent v-if="showComponents" />
				</div>
			</section>
			<div
				class="table-color"
				style="margin-top: -30px; margin-bottom: 30px; padding: 15px"
			>
				<b-button
					class="margin-bottom-15 width-250"
					style="
						background-color: rgb(182, 90, 92);
						color: white;
						margin-top: 50px;
					"
					@click="onClose"
				>
					Close & Make New Search
				</b-button>
			</div>
		</div>
	</LayoutWide>
</template>

<script>
import SubNavBar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import { ref, onMounted } from '@vue/composition-api'
import CustomerHeaderInfo from '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/components/CustomerHeaderInfo.vue'
import GetSelectedCustomer from '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/components/GetSelectedCustomer.vue'
import GetCustomerConsumptionsSubComponent from '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/components/GetCustomerConsumptionsSubComponent.vue'
import GetCustomerPaymentsHistorySubComponent from '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/components/GetCustomerPaymentsHistorySubComponent.vue'
// import GetCustomersLastFourWeeksDeliveriesSubComponent from '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/components/GetCustomersLastFourWeeksDeliveriesSubComponent.vue'
import useGetCustomerEconomicInfo from '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/scripts/useGetCustomerEconomicInfo.js'

export default {
	name: 'GetCustomerEconomicInfo',
	components: {
		SubNavBar,
		HeroBox,
		CustomerHeaderInfo,
		GetSelectedCustomer,
		// GetCustomersLastFourWeeksDeliveriesSubComponent,
		GetCustomerConsumptionsSubComponent,
		GetCustomerPaymentsHistorySubComponent,
	},
	setup() {
		const title = ref('Customer Economic Information')
		const subtitle = ref(
			'You can get customer economic information such as payment history , product consumption history and values.',
		)
		const color = ref('is-info')
		const { showSearch, showComponents } = useGetCustomerEconomicInfo()
		onMounted(() => {
			showSearch.value = true
			showComponents.value = false
		})
		const onClose = () => {
			showSearch.value = true
			showComponents.value = false
		}
		return {
			showSearch,
			showComponents,
			onClose,
			title,
			subtitle,
			color,
		}
	},
}
</script>

<style lang="scss" scoped></style>
